.ant-checkbox-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}



.ant-checkbox-inner::after {
   display: flex;
  align-items: center;
  justify-content: center;
}
@hack: true; @import "./src/styles/theme.less";