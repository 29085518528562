.custom-select .ant-select-selector {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); 
  margin: 0.5rem 0 !important; 

  outline: none; 
  border: 1px solid #d9d9d9 !important;
  background-color: #ffffff !important;
  border-radius: 0.375rem !important;
}

.custom-select .ant-select-selection-placeholder {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}

.custom-select .ant-select-selection-item {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}
