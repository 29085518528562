.modal {
  max-height: 784px;
  min-height: 389px;
  padding: 0;
  &--versions {
    .ant-modal-header {
      border-radius: 4px 4px 0 0;
      padding: 22px 16px !important;
    }
    .ant-modal-body {
      padding: 0 !important;
    }
  }
  &__bottomBorder {
    border-bottom: @border;
  }
  &__row {
    margin: 0 -32px;
    padding: 0 32px 32px 32px;
  }

  .ant-modal-content {
    border-radius: 4px;
    height: 100%;
    position: relative;
  }
  .ant-modal-header {
    border-radius: 4px 4px 0 0;
    padding: 24px 32px;
  }
  .ant-modal-body {
    padding: 32px 32px 0 32px;
    height: calc(100% - 159px);
    overflow-y: auto;
  }
  .ant-modal-footer {
    border-top: 0;
    padding: 24px 32px;
    width: 100%;
    margin-top: 100px;
    display: flex;
  }
  .ant-modal-footer button {
    height: 40px;
    font-weight: 600;
    padding: 0 40px;
  }
}
@hack: true; @import "./src/styles/theme.less";