.tables-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%; 
  overflow-x: auto;
}

.main-table {
  flex: 1; /* Takes up available space */
  overflow-x: auto; /* Enable horizontal scrolling if needed */

}

.last-column-table {
  flex-shrink: 0 !important;
  width: 250px !important;
}

.last-column-table-inner {
  font-weight: 500 !important;

  .ant-table-tbody .ant-table-row {
    background-color: #edf2f9 !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    background-color: #edf2f9 !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    background-color: unset !important;
  }

  /* Hover effect for table cells: no background color change */
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #edf2f9 !important; /* No background color change on hover */
  }

  /* Ensure last column also gets the styles */
  .ant-table-tbody td:last-child {
    background: #edf2f9 !important; /* No background color change on hover */
  }

  .ant-table-tbody td:last-child:hover {
    background: #edf2f9 !important; /* No background color change on hover */
  }

  .ant-table {
    min-width: 100%;
  }

  .ant-table-thead .ant-table-cell {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    height: 60px !important;
  }

  .ant-table-tbody .ant-table-cell {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 60px !important;
  }

  .table__action__menu {
    display: flex;
    justify-content: center;
  }

  .table__filter__icon {
    fill: @secondary-text;
  }

  .ant-table-thead {
    .ant-table-cell {
      color: @secondary-text;
      font-size: @text-sm;
      font-weight: 600;
      text-transform: uppercase;

      &::before {
        display: none;
      }

      .ant-table-filter-column {
        width: min-content;
        white-space: nowrap;

        .ant-table-column-title {
          margin-right: 2.2rem;
        }
      }
    }
  }

  .table__action__btn {
    text-align: center !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 14px;
    cursor: pointer;
    margin-right: 14px;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 4px;
    border-radius: 30px;
    padding: 4px 10px 4px 10px;
    width: 100px !important;
    height: 36px !important;
  }

  .table__action__btn--client {
    background-color: #daf8e6;
    color: #1a8245;
  }

  .table__action__btn--delete {
    background-color: #ffe7e7;
    color: #b62424;
  }

  .table__action__btn .anticon {
    margin-right: 4px;
  }

  .ant-skeleton,
  .ant-skeleton-input {
    width: 100% !important;
  }

  .ant-skeleton {
    margin-top: 16px;

    &:last-of-type {
      margin-bottom: 16px;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center !important;
    width: 100%;
    margin: 16px 0;
  }
}

.table__pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 16px 0;
}

.table {
  font-weight: 500 !important;

  .ant-table-tbody .ant-table-row:nth-child(even) {
    background-color: #edf2f9 !important; /* Background color for even rows */
  }

  /* Hover effect for even rows: keep the background color */
  .ant-table-tbody .ant-table-row:nth-child(even):hover {
    background-color: #edf2f9 !important; /* Ensure the background color stays the same */
  }

  /* General hover effect for all rows: no background color change */
  .ant-table-tbody .ant-table-row:hover {
    background-color: unset !important; /* Ensure no background color change on hover */
  }

  /* Hover effect for table cells: no background color change */
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: unset !important; /* No background color change on hover */
  }

  /* Ensure last column also gets the styles */
  .ant-table-tbody td:last-child {
    background-color: unset !important; /* Default background color */
  }

  .ant-table-tbody td:last-child:hover {
    background-color: unset !important; /* Background color on hover */
  }

  .ant-table {
    min-width: 100%;
  }

  .ant-table-thead .ant-table-cell {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    height: 60px !important;
  }

  .ant-table-tbody .ant-table-cell {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 60px !important;
  }

  .table__action__menu {
    display: flex;
    justify-content: center;
  }

  .table__filter__icon {
    fill: @secondary-text;
  }

  .ant-table-thead {
    .ant-table-cell {
      color: @secondary-text;
      font-size: @text-sm;
      font-weight: 600;
      text-transform: uppercase;

      &::before {
        display: none;
      }

      .ant-table-filter-column {
        width: min-content;
        white-space: nowrap;

        .ant-table-column-title {
          margin-right: 2.2rem;
        }
      }
    }
  }

  
  .ant-skeleton,
  .ant-skeleton-input {
    width: 100% !important;
  }

  .ant-skeleton {
    margin-top: 16px;

    &:last-of-type {
      margin-bottom: 16px;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center !important;
    width: 100%;
    margin: 16px 0;
  }
}

@hack: true; @import "./src/styles/theme.less";